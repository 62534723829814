import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { NavBar } from '../components'
import { Welcome, Visualize, Research, About } from '../pages'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/custom.scss'

function App() {
    return(
        <Router >
            <NavBar />
            <Switch style={{height: `calc(100vh - 80px)`}}> 
                <Route path="/visualize" exact component={Visualize} />
                <Route path="/research/*" exact component={Research} />
                <Route path="/about" exact component={About} />
                <Route path="/" exact component={Welcome} />
            </Switch>
        </Router>
    );
}

export default App

import React, {Component} from 'react';

export default class Overview extends Component { 
    render() {
        return(
            <div>
		<p className="display-4">Overview</p>
	    </div>
        );
    }
}


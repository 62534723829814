import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const List = styled.div.attrs({
    className: 'navbar-nav mr-auto'
})``

const Item = styled.div.attrs({
    className: 'collapse navbar-collapse'
})``

class Links extends Component {
    render() {
        return (
            <React.Fragment>
                <Link to="/" className='navbar-brand'>
                    <strong>Project Wombat</strong>
                </Link>
                <List>
                    <Item>
                        <Link to="/visualize" className="nav-link">
                            Visualize
                        </Link>
                    </Item>
                    <Item>
                        <Link to="/research" className="nav-link">
                            Research
                        </Link>
                    </Item>
                    <Item>
                        <Link to="/about" className="nav-link">
                            About
                        </Link>
                    </Item>
                </List>
            </React.Fragment>
        )
    }
}

export default Links
import { ResponsivePie } from '@nivo/pie'
import React, { Component } from 'react'

class TierShareChart extends Component {
    constructor(props) {
	super(props);
        let val = "rgb(80,115,199)";
        if (this.props.color) {
            val = this.props.color;
        }
        this.state = { color: val  };
    }

    render () {
        return (
            <ResponsivePie
                data={[
                    {
                        "id": "share",
                        "label": "share",
                        "value": this.props.data,
                        "color": this.state.color
                    },
                    {
                        "id": "other",
                        "label": "other",
                        "value": (100 - this.props.data),
                        "color": "rgb(123, 151, 219)"
                    }
                ]}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                padding={{ top: 0, right: 0, bottom: 0, left: 0 }}
                innerRadius={0}
                padAngle={0.7}
                cornerRadius={0}
                colors={["rgb(80, 115, 199)", "rgb(123, 151, 219)"]}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                enableRadialLabels={false}
                enableSlicesLabels={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                isInteractive={false}
            />
        )
    }
}

export default TierShareChart;

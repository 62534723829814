import React, { Component } from 'react'
import styled from 'styled-components'

class About extends Component {
    render() {
        return (
            <div className="basic-cont" style={{margin:"15px"}}>
                <p>this shows information about project goals</p>
            </div>
        )
    }
}

export default About

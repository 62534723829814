import React, {Component} from 'react';

export default class Discussion extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">Discussion</p>
		<p>sources of data on supply chains, modeling is not perfect, much of the information is guesswork, limited by the appropriateness of proxies used in risk scores, company supply chain data, and model considerations (distance), simple concept of consumption for modeling supply chains</p>
            </div>
        );
    }
}


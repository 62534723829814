import React, {Component} from 'react';

export default class References extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">References</p>
            </div>
        );
    }
}


import React, { Component } from 'react'

import { InteractiveMap } from '../components'
import styled from 'styled-components'

const Container = styled.div.attrs({
    className: 'container',
})`
    height: calc(100vh - 80px);
    margin: 0%;
    padding: 0%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    overflow: hidden;
`

const Viewport = styled.div.attrs({
    className: 'viewport',
})`
    z-index: 0;
`

class Visualize extends Component {
    render() {
        return (
            <Container>
                <Viewport>
                    <InteractiveMap style={{overflow: "hidden", height: `calc(100vh-80px)`}} />
                </Viewport>
            </Container>
        )
    }
}

export default Visualize
import React, { Component } from 'react'
import { Nav, Navbar, NavDropown } from 'react-bootstrap'

import Logo from './Logo'
import Headroom from "headroom.js";

class NavBar extends Component {
    componentDidMount() {
        let headroom = new Headroom(document.querySelector(".main-nav"));
        headroom.init();
    }

    render() {
        return (
            <Navbar expand="lg" className="main-nav" variant="dark">
                <Navbar.Brand href="/"><Logo /> Project Wombat </Navbar.Brand>
		<Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="main-collapse-navbar">
		    <Nav className="mr-auto" variant="dark">
                      <Nav.Link href="/visualize">Visualize</Nav.Link>
                      <Nav.Link href="/research/">Research</Nav.Link>
                      <Nav.Link href="/about">About</Nav.Link>
                    </Nav>
		</Navbar.Collapse>
            </Navbar>
        )
    }
}

export default NavBar

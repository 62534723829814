import React, {Component} from 'react';

export default class SupplyChains extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">Supply Chains</p>
		<p>purpose is to model the relationship between suppliers of different tiers and provide a reasonable estimation of the supply chain</p>
            </div>
        );
    }
}


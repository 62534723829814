import React, {Component} from 'react'
import { Card, ProgressBar } from 'react-bootstrap'

// import TierShareChart from './TierShareChart'

// <TierShareChart data={this.props.data.srcShare} style={{minWidth: "50px"}}/>
// <TierShareChart data={this.props.data.destShare} style={{minWidth: "50px"}}/>

class ConnectionDetails extends Component {
    
    render() {
        return (
            <Card id="connectionDetails" style={{padding: "10px", margin: "0px", width: "100%", height: "100%", border: "none", overflowY: "auto"}}>
                <Card.Title>{this.props.data.company}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Company</Card.Subtitle>
        <Card.Text><strong>{this.props.data.src}</strong> supplying <strong>{this.props.data.dest}</strong> makes up <strong>{this.props.data.tierShare.toFixed(2)}%</strong> of the trade flow from <strong>{this.props.data.tierDesc}</strong>.</Card.Text>
                <Card.Text>The trade resilience score for this connection is <strong>{this.props.data.tradeScore.toFixed(2)}</strong>.</Card.Text>
                <hr />
                <Card.Title>{this.props.data.src}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Source</Card.Subtitle>
                    <div>
                        <ProgressBar now={this.props.data.srcShare} style={{minWidth: "50px"}}/>
                    </div>
                    <Card.Text><strong>{this.props.data.srcShare.toFixed(2)}%</strong> of the output of <strong>{this.props.data.src}</strong> is supplying <strong>{this.props.data.dest}</strong>.</Card.Text>
                <hr />
                <Card.Title>{this.props.data.dest}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Destination</Card.Subtitle>
                    <div>
                        <ProgressBar now={this.props.data.destShare} style={{minWidth: "50px"}}/>
                    </div>
                    <Card.Text><strong>{this.props.data.destShare.toFixed(2)}%</strong> of the needs of <strong>{this.props.data.dest}</strong> are supplied by <strong>{this.props.data.src}</strong>.</Card.Text>
            </Card>
        )
    }
}

export default ConnectionDetails

import React from 'react';
import {Badge} from 'react-bootstrap';
import { ResponsivePieChart } from '@nivo/pie';

import TierShareChart from './TierShareChart';
import CountryColors from '../styles/country_colors.json';

export default class ConnectionTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titleStr: this.props.data.src + " to  " + this.props.data.dest,
            destColor: "#" + CountryColors[this.props.data.dest],
            srcColor: "#" + CountryColors[this.props.data.src]
        };
    }

    render() {
        return(
            <div style={{padding: "10px", maxWidth: "400px"}}>
                <div style={{width: "100%",display:"flex", flexDirection: "row", alignItems: "flex-start"}}>
                    <h2 style={{paddingRight:"10px"}}>{this.state.titleStr}</h2>
                    <Badge variant="secondary">{this.props.data.company}</Badge>
                </div>
                <div>{this.props.data.tierDesc}</div>
		<div style={{display:"flex", flexDirection: "row"}}>
		<div style={{display:"flex", flexDirection: "column", padding: "10px", justifyContent: "center"}}>
		    <div>
                        <p>{this.props.data.srcShare.toFixed(2)}% of the output of {this.props.data.src}</p>
                    </div>
		    <div style={{minHeight: "100px", width: "100px"}}>
                        <TierShareChart data={this.props.data.srcShare} color={this.state.srcColor} style={{minWidth: "50px"}}/>
                    </div>
		</div>
		<div style={{display:"flex", flexDirection: "column", padding: "10px", justifyContent: "center"}}>
		    <div>
                        <p>{this.props.data.destShare.toFixed(2)}% of the input of {this.props.data.dest}</p>
                    </div>
                    <div style={{minHeight: "100px", width: "100px"}}>
                        <TierShareChart data={this.props.data.destShare} color={this.state.destColor} style={{minWidth: "50px"}}/>
                    </div>
		</div>
		</div>
            </div>
        );
    }
}

import { ResponsivePie } from '@nivo/pie'
import React, { Component } from 'react'

import CountryCodes from '../styles/country_colors.json';

class InteractivePieChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
	    colors: []
        }
    }

    componentDidMount(props) {
        let formattedData = []
	let colorsData = []
        let temp = this.props.data
        Object.keys(temp).forEach((index) => {
            formattedData.push({
                id: index,
                label: index,
                value: (temp[index] * 100.0).toFixed(2)
            })
	    colorsData.push('#' + CountryCodes[index]);
        })
        this.setState({ data: formattedData, colors: colorsData })
	//borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
    }

    render () {
        return (
            <ResponsivePie
                data={this.state.data}
                margin={{ top: 15, right: 0, bottom: 15, left: 0 }}
                innerRadius={0}
                padAngle={0}
                cornerRadius={0}
                colors={this.state.colors}
                borderWidth={1}
                borderColor={'#000'}
                enableRadialLabels={false}
                enableSlicesLabels={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 56,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        symbolSize: 18,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        )
    }
}

export default InteractivePieChart;

import React, {Component} from 'react';

export default class Companies extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">Countries</p>
		<p>sources and discuss quality of supply chain documentation, overview of generalizations</p>
            </div>
        );
    }
}


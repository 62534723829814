import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'

import logo from '../wombat.svg'

const divStyle = {margin: "0px", 
    padding: "15px",
    height: 'calc(100% - 80px)',
    fallbacks: [
        { height: '-moz-calc(100% - 80px)' },
        { height: '-webkit-calc(100% - 80px)' },
        { height: '-o-calc(100% - 80px)' }
    ], width: "100vw", 
    backgroundColor: "#EFEFEF", 
    textAlign: "center"
}

class Loading extends Component {
    render() {
        return (
            <React.Fragment>
            <div id="banner-placeholder" style={{height: "80px"}}></div>
            <div style={divStyle}>
                <img alt="Wombat silhouette" style={{display: "block", marginLeft: "auto", marginRight: "auto"}} src={logo} width="375px" height="375px" />
                <div style={{marginBottom: "15px", fontSize: "22px"}}>
                    <div>Loading your visualization...</div>
		    <div><Spinner animation="border" /></div>
		    <div>{this.props.statusMessage}</div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default Loading

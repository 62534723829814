import React, { Component } from 'react';
import { Nav, Row, Col } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom'
import { Overview, CountryScores, Companies, SupplyChains, Discussion, Appendix, References } from '../components'

class Research extends Component {
    render() {
        return (
	    <Col className="lg-12 research-container basic-cont">
            <Row className="container">
		<Col xs="12" sm="12" md="12" lg="4" xl="4">
		    <p className="display-4">Research</p>
		    <Nav defaultActiveKey="/overview" className="flex-column research">
		        <Nav.Link href="/research/">Overview</Nav.Link>
			<Nav.Link href="/research/country-scores">Country Scores</Nav.Link>
			<Nav.Link href="/research/companies">Companies</Nav.Link>
			<Nav.Link href="/research/supply-chains">Supply Chains</Nav.Link>
			<Nav.Link href="/research/discussion">Discussion</Nav.Link>
			<Nav.Link href="/research/appendix">Appendix</Nav.Link>
			<Nav.Link href="/research/references">References</Nav.Link>
		    </Nav>
		</Col>
		<Col xs="12" sm="12" md="12" lg="8" xl="8">
		    <Switch>
			<Route path='/research/country-scores' component={CountryScores} />
			<Route path='/research/companies' component={Companies} />
			<Route path='/research/supply-chains' component={SupplyChains} />
			<Route path='/research/discussion' component={Discussion} />
			<Route path='/research/appendix' component={Appendix} />
			<Route path='/research/references' component={References} />
			<Route path='/research/' component={Overview} />
		    </Switch>
		</Col>
            </Row>
	    </Col>
        )
    }
}

export default Research

import React, {Component} from 'react';

export default class Appendix extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">Appendix</p>
		<p>charts, supplier data tabulated, country risk tabulated, equations, net utilization (out of 4) versus risk, graph?</p>
            </div>
        );
    }
}


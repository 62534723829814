import React, { Component } from 'react'
import { Card, Collapse, Form, Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare, faPlus, faMinus, faBars } from '@fortawesome/free-solid-svg-icons'

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterSettings: {},
	    toggles: {},
	    collapsed: false
        }
        this.toggleCompany = this.toggleCompany.bind(this)
        this.toggleCompanyTier = this.toggleCompanyTier.bind(this)
	this.toggle = this.toggle.bind(this)
        this.submit = this.submit.bind(this)
    }

    componentDidMount(props) {
        let temp = {}
        Object.keys(this.props.data).forEach((company) => {
            temp[company] = []
            Object.keys(this.props.data[company].tiers).forEach((tier) => {
                this.props.data[company].tiers[tier].forEach((subObject, idx) => {
                    temp[company].push({
                        tier: tier,
                        index: idx,
                        key: company + "-" + tier + "-" + idx
                    })
                })
            })
        })
	let toggles = {};
	Object.keys(this.props.data).forEach((company) => {
	    toggles[company] = true;
	})
        this.setState({ filterSettings: this.props.data, filterList: temp })
    }

    async toggleCompany(company) {
        let temp = this.state.filterSettings
        temp[company].selected = !temp[company].selected
        await Object.keys(temp[company].tiers).forEach((tier) => {
            temp[company].tiers[tier].forEach((subObject, index) => {
                temp[company].tiers[tier][index].selected = temp[company].selected
            })
        })
        this.setState({ filterSettings: temp })
        console.log('toggle company', temp)
    }

    toggleCompanyTier(company, tier, index) {
        let temp = this.state.filterSettings
        if (!temp[company].selected && !temp[company].tiers[tier][index].selected) {
            temp[company].selected = true
        } else {
            let countTrue = 0
            Object.keys(temp[company].tiers).forEach((tier) => {
                temp[company].tiers[tier].forEach((subObject) => {
                    if (subObject.selected) {
                        countTrue += 1
                    }
                })
            })
            if (temp[company].selected && countTrue === 1 && temp[company].tiers[tier][index].selected) {
                temp[company].selected = false
            }
        }
        temp[company].tiers[tier][index].selected = !temp[company].tiers[tier][index].selected
        this.setState({ filterSettings: temp })
    }

    submit() {
        this.props.getFilterData(this.state.filterSettings)
    }

    toggle(company) {
	let temp = this.state.toggles;
	temp[company] = !temp[company];
        this.setState({toggles: temp})
    }

    render(props) {
        return (
            <div id="control-panel">
                <Card style={{maxWidth: "400px", height: `calc(100vh - 80px)`, marginBottom: "10px"}}>
                    <Card.Body>
		        <Table hover style={{width: "100%"}}>
			    <thead style={{display:"table", width:"100%"}}>
				<tr style={{display:"flex"}}>
				    <Collapse in={!this.state.collapsed} >
				        <td style={{flexGrow:"3"}}><strong>Filter Supply Chain Data</strong></td>
				    </Collapse>
				    <td>
				        <Button variant="icon" onClick={()=>{this.setState({collapsed: !this.state.collapsed})}}><FontAwesomeIcon icon={faBars}/></Button>
				    </td>
				</tr>
			    </thead>
			    <Collapse in={!this.state.collapsed} >
			    <tbody>
                            {Object.keys(this.state.filterSettings).map((company) => 
                                <div key={{company}}>
                                        <tr style={{ display: "flex", minWidth: "350px"}}>
                                            <td>
						{!this.state.filterSettings[company].selected && <Button variant="icon" onClick={() => {this.toggleCompany(company)}}><FontAwesomeIcon icon={faSquare}/></Button>}
                                                {this.state.filterSettings[company].selected && <Button variant="icon" onClick={() => {this.toggleCompany(company)}}><FontAwesomeIcon icon={faCheckSquare}/></Button>}   
                                            </td>
                                            <td style={{ flexGrow: "2" }}>{company}</td>
                                            <td>
						{this.state.toggles[company] && <Button variant="icon" onClick={()=>{this.toggle(company)}}><FontAwesomeIcon icon={faMinus}/></Button>}
						{!this.state.toggles[company] && <Button variant="icon" onClick={()=>{this.toggle(company)}}><FontAwesomeIcon icon={faPlus}/></Button>}
                                            </td>
					</tr>
                                        <Collapse in={this.state.toggles[company]}>
					    <div style={{paddingLeft:"10px"}}>
                                                {this.state.filterList[company].map((subObject) => (
						    <tr className="subRow">
						    <td>
                                                        {!this.state.filterSettings[company].tiers[subObject.tier][subObject.index].selected && <Button variant="icon" onClick={()=>{this.toggleCompanyTier(company, subObject.tier, subObject.index)}}><FontAwesomeIcon icon={faSquare} /></Button>}
							{this.state.filterSettings[company].tiers[subObject.tier][subObject.index].selected && <Button variant="icon" onClick={()=>{this.toggleCompanyTier(company, subObject.tier, subObject.index)}}><FontAwesomeIcon icon={faCheckSquare} /></Button>}
                                                    </td>
                                                    <td colSpan="2">
                                                        {this.state.filterSettings[company].tiers[subObject.tier][subObject.index].desc}
                                                    </td>
						    </tr>
                                                ))}
                                            </div>
                                        </Collapse>
                                </div>
                            )}
			    </tbody>
			    </Collapse>
			</Table>
                        <Collapse in={!this.state.collapsed} ><Button onClick={this.submit}>Refresh Visualization</Button></Collapse>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default SideBar

/**
 * {Object.keys(this.state.filterSettings[company].tiers).forEach((tier) => {
                                                    {this.state.filterSettings[company].tiers[tier].map((subObject, index) => (
                                                        <InputGroup key={subObject.desc} style={{ display: "flex" }}>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Checkbox 
                                                                checked={subObject.selected} 
                                                                onChange={() => {this.toggleCompanyTier(company, tier, index)}} 
                                                            />
                                                        </InputGroup.Prepend>
                                                        <InputGroup.Text style={{ flexGrow: "2" }}>
                                                            {subObject.desc}
                                                        </InputGroup.Text>
                                                        </InputGroup>
                                                    ))}
                                                })}
 */

import React, {Component} from 'react';

export default class CountryScores extends Component { 
    render() {
        return(
            <div>
                <p className="display-4">Country Scores</p>
		<p>Risk Scores → "country risk" → Compilation of rank scores to determine the riskiness of conducting supply chain operations from that country — danger that any significant change a country may pose to supply chains that the country is part of</p>
            </div>
        );
    }
}


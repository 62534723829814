import React, {Component} from 'react'
import { Row, Col, Card, Button, Table, Collapse } from 'react-bootstrap'
import ReactCountryFlag from 'react-country-flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import IsoCodes from '../styles/country_iso.json'
import InteractivePieChart from './InteractivePieChart'

class ConnectionDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            queries: {},
            isLoading: true,
	    eventKeys: {},
	    countryIso: IsoCodes[this.props.data.country]
        }
	this.toggle = this.toggle.bind(this);
    }

    componentDidMount(props) {
        let queries = {}
        Object.keys(this.props.data.info.companyData).forEach((company, cIndex) => {
            let tempFilter = this.props.data.info.filterData[company]
            if (tempFilter.selected && this.props.data.info.companyData[company] !== {}) {
                queries[company] = []
                Object.keys(tempFilter.tiers).forEach((tier) => {
                    tempFilter.tiers[tier].forEach((subObject, subTierIndex) => {
                        if (subObject.selected) {
                            let splitResult = subObject.desc.split(" to ")
                            queries[company].push({
                                conn: parseInt(tier),
                                subTier: subTierIndex,
                                type: "out",
                                curr: splitResult[0], 
                                other: splitResult[1]
                            })
                            queries[company].push({
                                conn: (parseInt(tier)+1), 
                                subTier: 0,
                                type: "in",
                                curr: splitResult[1],
                                other: splitResult[0]
                            })
                        }
                    })
                })
            }
        })
        this.setState({ queries: queries, isLoading: false})
    }

    toggle (value) {
        let temp = this.state.eventKeys;
        if (temp[value]) {
            temp[value] = !temp[value];
        } else {
            temp[value] = true;
        }
        this.setState({eventKeys: temp});
    }

    render(props) {
        console.log(this.props.data)
        return (
            <Card id="countryDetails" style={{padding: "10px", margin: "0px", width: "100%", height: "100%", border: "none", overflowY: "auto"}}>
		<Table hover style={{width:"100%"}}>
                <thead style={{backgroundColor:"#f4f4f4"}}>
			<tr><td colSpan="3"><div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
				<div>
				    <p className="display-4 table-subtitle">{this.props.data.country}</p>
				</div>
				<div style={{padding: "5px"}}><ReactCountryFlag countryCode={this.state.countryIso} svg style={{width:'2em',height:'2em'}} shiny={true} /></div>
			</div></td></tr>
		</thead>
		<tbody>
                {!this.state.isLoading && Object.keys(this.props.data.info.companyData).map((company, index) => (
                    <div key={index}>
                    <tr style={{ display: "flex", minWidth: "350px", width: "100%"}}>
                        <td style={{flexGrow: "3"}}>{company}</td>
			<td>
			  {this.state.eventKeys[company] && this.state.eventKeys[company] == true && <Button variant="icon" onClick={()=>{this.toggle(company)}}><FontAwesomeIcon icon={faMinus}/></Button>}
			  {(!this.state.eventKeys[company] || this.state.eventKeys[company] == false)&&  <Button variant="icon" onClick={()=>{this.toggle(company)}}><FontAwesomeIcon icon={faPlus}/></Button>}
			</td>		    
		    </tr>
		    <tr>
                        <Collapse in={this.state.eventKeys[company]}>
                        <div style={{paddingLeft: "10px"}}>
                            {this.state.queries[company].map((connection, cIndex) => (
				<>
                                {this.props.data.info.companyData[company][connection.conn] && this.props.data.info.companyData[company][connection.conn][connection.subTier] && Object.keys(this.props.data.info.companyData[company][connection.conn][connection.subTier][connection.type][connection.other]).length !== 0 &&
                                <tr className="subRow" key={cIndex} style={{width:"100px"}}>
                                    <div>
                                        <tr>
                                            <td onClick={()=>this.toggle(connection.conn + '-' + connection.subTier + '-' + connection.curr + '-' + connection.other)}>
                                                {connection.type === "in" && (connection.curr + " Inflows of " + connection.other)}
                                                {connection.type === "out" && (connection.curr + " Outflows for " + connection.other)}
                                            </td>
                                        </tr>
                                        <Collapse in={this.state.eventKeys[connection.conn + '-' + connection.subTier + '-' + connection.curr + '-' + connection.other]}>
                                            <tr><td style={{height: "300px"}}>
                                                <InteractivePieChart
                                                    data={this.props.data.info.companyData[company][connection.conn][connection.subTier][connection.type][connection.other]}
                                                />
                                            </td></tr>
                                        </Collapse>
                                    </div>
                                </tr>
                                }
				</>
                            ))}
                        </div>
                        </Collapse>
                    </tr>
                    </div>
                ))}
		</tbody>
		</Table>
            </Card>
        )
    }
}

export default ConnectionDetails

import React, {Component} from 'react'
import { Card, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

import ConnectionDetails from './ConnectionDetails'
import CountryDetails from './CountryDetails'

class SlideInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            width: "400px"
        };
	this.onAdjust = this.onAdjust.bind(this);
    }

    onAdjust() {
	if (this.state.expanded) {
            this.setState({ expanded: false, width: "400px" });
        } else {
            this.setState({ expanded: true, width: "80vw"});
        }
    }

    render(props) {
        return (
	    <div style={{display: "flex", flexDirection: "row"}}>
            <Card id="slideInfo" style={{width: this.state.width, minWidth: this.state.width, height: `calc(100vh - 80px)`, marginBottom: "10px"}}>
                <Card.Body style={{padding: "0px", width: "100%"}}>
                    {this.props.type === "connection" && <ConnectionDetails data={this.props.data}/>}
                    {this.props.type === "country" && <CountryDetails data={this.props.data}/>}
                    {this.props.type === "loading" &&
                        <h1>Loading...</h1>
                    }
                </Card.Body>
            </Card>
	    <div style={{backgroundColor: "white", height:"fit-content", display:"flex", flexDirection: "column"}}>
	        <Button style={{ width: "50px", height: "50px", padding: "0px", margin:"10px" }} onClick={this.props.onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </Button>
		{this.state.expanded &&
                    <Button style={{ width: "50px", height: "50px", padding: "0px", margin:"10px" }} onClick={this.onAdjust}>
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
		}
		{!this.state.expanded &&
		    <Button style={{ width: "50px", height: "50px", padding: "0px", margin:"10px" }} onClick={this.onAdjust}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                }
	    </div>
	    </div>
        )
    }
}

export default SlideInfo

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import logo from '../wombat.svg'

class Welcome extends Component {
    render() {
        return (
            <div className="basic-cont" style={{padding: "15px", marginTop:"80px", height: `calc(100vh - 80px)`, textAlign: "center"}}>
                <img alt="Wombat silhouette" style={{display: "block", marginLeft: "auto", marginRight: "auto"}} src={logo} width="375px" height="375px" />
                <div style={{marginBottom: "15px", fontSize: "22px"}}>
                    Visualize the global supply chains which allow some of the economy's largest companies to operate.
                </div>
                <Link to="/visualize">
                    <Button style={{display: "block", marginLeft: "auto", marginRight: "auto"}} variant="dark">
                        Visualize
                    </Button>
                </Link>
            </div>
        )
    }
}

export default Welcome

import React, { Component } from 'react';
import DeckGL, {GeoJsonLayer, ArcLayer} from 'deck.gl';
import {Spinner} from 'react-bootstrap';

import SideBar from './SideMenu'
import SlideInfo from './SlideInfo'
import Loading from './Loading'
import ConnectionTooltip from './ConnectionTooltip'

import Color from "color";
import DataFetcher from './data/centroid';
import { useState } from 'react';

// var preUrl = 'http://localhost:3000/'

const INITIAL_VIEW_STATE = {
  latitude: 0, longitude: 0, zoom: 1, bearing: 0, pitch: 15
};

const givenColor = Color('#b1e400');

class InteractiveMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hoveredObject: null,
      tooltipData: null,
      passedData: null,
      map: [],
      centroids: [],
      centroidsDict: {},
      connections: [],
      isLoading: true,
      loadStatus: "",
      showInfoBox: false,
      startingUp: true,
      companyParams: {
        "country": "",
        "companies": {
          "Apple Inc.": {
            "tier": [],
            "visible": true
          },
          "Deere & Company": {
            "tier": [],
            "visible": true
          },
          "Dell Technologies": {
            "tier": [],
            "visible": true
          },
          "Nike Inc.": {
            "tier": [],
            "visible": true
          }
        }
      },
      totalCount: 6,
      filterSettings: {
        "Apple Inc.": {
          selected: true,
          tiers: {
            1: [{
                desc: "Raw Materials to Manufacturers",
                selected: true
            }],
            2: [{
                desc: "Manufacturers to Distributors",
                selected: true
            }],
            3: [{
                desc: "Distributors to Retailers",
                selected: true
            }]
          }
        },
        "Deere & Company": {
          selected: true,
          tiers: {
            1: [{
                desc: "Raw Materials to Manufacturers",
                selected: true
            }],
            2: [{
                desc: "Manufacturers to Distributors",
                selected: true
            }],
            3: [{
                desc: "Distributors to Retailers",
                selected: true
            }]
          }
        },
        "Dell Technologies": {
          selected: true,
          tiers:{
            1: [{
              desc: "Tantalum to Manufacturers",
              selected: true
            }, {
              desc: "Tin to Manufacturers",
              selected: true
            }, {
              desc: "Gold to Manufacturers",
              selected: true
            },{
              desc: "Tungsten to Manufacturers",
              selected: true
            }],
            2: [{
              desc: "Manufacturers to Distributors",
              selected: true
            }],
            3: [{
                desc: "Distributors to Retailers",
                selected: true
            }]
          }
        },
        "Nike Inc.": {
          selected: true,
          tiers: {
            1: [{
                desc: "Raw Materials to Manufacturers",
                selected: true
            }],
            2: [{
                desc: "Manufacturers to Distributors",
                selected: true
            }],
            3: [{
                desc: "Distributors to Retailers",
                selected: true
            }]
          }
        }
      }
    }
    this._onHover = this._onHover.bind(this)
    this._onCountryClick = this._onCountryClick.bind(this)
    this._onConnectionHover = this._onConnectionHover.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this._onConnectionClick = this._onConnectionClick.bind(this)
    this.setCompanyParams = this.setCompanyParams.bind(this)
    this.updateCompanies = this.updateCompanies.bind(this)
  }

  toUrlString( objectVar, country ) {
    let returnStr = ""
    if (objectVar.visible) {
      returnStr += ("visible=" + objectVar.visible)
    }
    if (objectVar.tier) {
      objectVar.tier.forEach((str) => {
        returnStr += ("&tier=" + str)
      })
    }
    if (country !== "") {
      returnStr += ("&country=" + country)
    }
    return returnStr
  }

  pushArcs(connections, name) {
    let arcs = []
    Object.keys(connections).forEach((tier) => {
      connections[tier].forEach((subTier, subTierIndex) => {
        subTier.forEach((connection) => {
          arcs.push({
            format: "arc",
            company: name,
            tier: tier,
            tierDesc: this.state.filterSettings[name].tiers[parseInt(tier)][subTierIndex].desc,
            src: connection[0],
            srcGeo: this.state.centroidsDict[connection[0]],
            dest: connection[1],
            destGeo: this.state.centroidsDict[connection[1]],
            netShare: connection[2],
            srcShare: connection[3],
            destShare: connection[4],
            tradeScore: connection[5]
          })
        })
      })
    })
    return arcs
  }

  async componentDidMount() { 
    this.setState({ loadStatus: "Locating centroids" });
   /*  await fetch('api/centroids')
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new console.log('Something went wrong ...');
            }
        })
        .then((data) => {
          let cents = {}
          data.forEach((feature) => {
            let key = feature.properties.ADMIN
            if (key === "Hong Kong S.A.R.") key = "Hong Kong"
            if (key === "Czechia") key = "Czech Republic"
            if (key === "United States of America") key = "United States"
            cents[key] = feature.geometry.coordinates
          })
          this.setState({ centroids: data, centroidsDict: cents })
        }); */
    const centroidVal = DataFetcher.getCentroids();
    let cents = {};
    centroidVal.forEach((feature) => {
      let key = feature.properties.ADMIN
      if (key === "Hong Kong S.A.R.") key = "Hong Kong"
      if (key === "Czechia") key = "Czech Republic"
      if (key === "United States of America") key = "United States"
      cents[key] = feature.geometry.coordinates
    });
    this.state.centroids = centroidVal;
    this.state.centroidsDict = cents;
    
    this.setState({ loadStatus: "Drawing map" })

    const map = DataFetcher.getMap();
    this.state.map = map;

    this.setState({ loadStatus: "Reading Apple" })
    
      const result = DataFetcher.getAapl(
        this.state.companyParams.companies["Apple Inc."].visible,
        (this.state.companyParams.companies["Apple Inc."].tier) ? this.state.companyParams.companies["Apple Inc."].tier : null,
        (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
      );
      if (result != false && result.connections) {
        this.setState({ arcData: this.pushArcs(result.connections, "Apple Inc.") })
      }
      
    this.setState({ loadStatus: "Reading Deere & Co" })

      const result2 = DataFetcher.getDe(
        this.state.companyParams.companies["Deere & Company"].visible,
        (this.state.companyParams.companies["Deere & Company"].tier) ? this.state.companyParams.companies["Deere & Company"].tier : null,
        (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
      );
      if (result2 != false && result2.connections) {
        this.setState({ arcData2: this.pushArcs(result2.connections, "Deere & Company") })
      }
  
      this.setState({ loadStatus: "Reading Dell Technologies" })

      const result3 = DataFetcher.getDell(
        this.state.companyParams.companies["Dell Technologies"].visible,
        (this.state.companyParams.companies["Dell Technologies"].tier) ? this.state.companyParams.companies["Dell Technologies"].tier : null,
        (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
      );
      if (result3 != false && result3.connections) {
        this.setState({ arcData3: this.pushArcs(result3.connections, "Dell Technologies") })
      }
  
      this.setState({ loadStatus: "Loading Nike" })
      
      const result4 = DataFetcher.getNke(
        this.state.companyParams.companies["Nike Inc."].visible,
        (this.state.companyParams.companies["Nike Inc."].tier) ? this.state.companyParams.companies["Nike Inc."].tier : null,
        (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
      );
      if (result4 != false && result4.connections) {
        this.setState({ arcData4: this.pushArcs(result4.connections, "Nike Inc.") })
      }
  
      this.setState({ isLoading: false, loadStatus: "Done", startingUp: false })
  }

  async updateCompanies() {
    const result = DataFetcher.getAapl(
      this.state.companyParams.companies["Apple Inc."].visible,
      (this.state.companyParams.companies["Apple Inc."].tier) ? this.state.companyParams.companies["Apple Inc."].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    console.log(result)
    if (result != false && result.connections) {
      this.setState({ arcData: this.pushArcs(result.connections, "Apple Inc.") })
    }

    const result2 = DataFetcher.getDe(
      this.state.companyParams.companies["Deere & Company"].visible,
      (this.state.companyParams.companies["Deere & Company"].tier) ? this.state.companyParams.companies["Deere & Company"].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result2 != false && result2.connections) {
      this.setState({ arcData2: this.pushArcs(result2.connections, "Deere & Company") })
    }

    const result3 = DataFetcher.getDell(
      this.state.companyParams.companies["Dell Technologies"].visible,
      (this.state.companyParams.companies["Dell Technologies"].tier) ? this.state.companyParams.companies["Dell Technologies"].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result3 != false && result3.connections) {
      this.setState({ arcData3: this.pushArcs(result3.connections, "Dell Technologies") })
    }
    
    const result4 = DataFetcher.getNke(
      this.state.companyParams.companies["Nike Inc."].visible,
      (this.state.companyParams.companies["Nike Inc."].tier) ? this.state.companyParams.companies["Nike Inc."].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result4 != false && result4.connections) {
      this.setState({ arcData4: this.pushArcs(result4.connections, "Nike Inc.") })
    }

    await this.setState({ isLoading: false })
  }

  async getCountry() {
    let returnData = {}

    const result = DataFetcher.getAapl(
      this.state.companyParams.companies["Apple Inc."].visible,
      (this.state.companyParams.companies["Apple Inc."].tier) ? this.state.companyParams.companies["Apple Inc."].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result != false) {
      returnData["Apple Inc."] = result;
    }

    const result2 = DataFetcher.getDe(
      this.state.companyParams.companies["Deere & Company"].visible,
      (this.state.companyParams.companies["Deere & Company"].tier) ? this.state.companyParams.companies["Deere & Company"].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result2 != false) {
      returnData["Deere & Company"] = result;
    }

    const result3 = DataFetcher.getDell(
      this.state.companyParams.companies["Dell Technologies"].visible,
      (this.state.companyParams.companies["Dell Technologies"].tier) ? this.state.companyParams.companies["Dell Technologies"].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result3 != false) {
      returnData["Dell Technologies"] = result;
    }
    
    const result4 = DataFetcher.getNke(
      this.state.companyParams.companies["Nike Inc."].visible,
      (this.state.companyParams.companies["Nike Inc."].tier) ? this.state.companyParams.companies["Nike Inc."].tier : null,
      (this.state.companyParams.country !== '') ? this.state.companyParams.country : null
    );
    if (result4 != false) {
      returnData["Nike Inc."] = result;
    }
    
    return returnData
  }

  async _onCountryClick(info) {
    this.setState({ clickType: "loading", clickType: "loading", clickedObject: info.object, connection: false, hoveredObject: info.object })
    if (info.object) {
      let data = info.object.properties
      let temp = this.state.companyParams
      
      let cntry = data.ADMIN
      if (cntry === "Hong Kong S.A.R.") cntry = "Hong Kong"
      if (cntry === "Czechia") cntry = "Czech Republic"
      if (cntry === "United States of America") cntry = "United States"
      temp.country = cntry

      let passedData = {
        companyData: await this.getCountry(),
        filterData: this.state.filterSettings
      }
      this.setState({ companyParams: temp, 
        passedData: {
          country: data.ADMIN,
          info: passedData
        }, 
        clickType: "country", showInfoBox: true})
    }
  }

  _onHover({x, y, object}) {
    this.setState({x, y, hoveredObject: object, connection: false});
  }

  async _onConnectionHover(info) {
    if (info.object != this.state.hoveredObject) {
    this.setState({ x: info.x, y: info.y, connection:true, hoveredObject: info.object })
    if (this.state.hoveredObject && this.state.hoveredObject.company) {
      this.setState({tooltipLoading: true});
      let passedData = {
        company: this.state.hoveredObject.company,
        tier: parseInt(this.state.hoveredObject.tier),
        tierDesc: this.state.hoveredObject.tierDesc,
        src: this.state.hoveredObject.src,
        dest: this.state.hoveredObject.dest,
        srcShare: this.state.hoveredObject.srcShare * 100,
        destShare: this.state.hoveredObject.destShare * 100,
        tierShare: this.state.hoveredObject.netShare * 100,
        tradeScore: this.state.hoveredObject.tradeScore
      };
      this.setState({ tooltipData: passedData, tooltipLoading: false });
    }
    }
  }

  async _onConnectionClick (info) {
    this.setState({ isLoading: true, clickedObject: info.object, hoveredObject: info.object, connection: true})
    let passedData = {
      company: this.state.clickedObject.company,
      tier: parseInt(this.state.clickedObject.tier),
      tierDesc: this.state.clickedObject.tierDesc,
      src: this.state.clickedObject.src,
      dest: this.state.clickedObject.dest,
      srcShare: this.state.clickedObject.srcShare * 100,
      destShare: this.state.clickedObject.destShare * 100,
      tierShare: this.state.clickedObject.netShare * 100,
      tradeScore: this.state.clickedObject.tradeScore
    };
    this.setState({ clickType: "connection", passedData: passedData, showInfoBox: true, isLoading: false })
  }

  setCompanyParams(filterSettings) {
    let temp = this.state.companyParams
    Object.keys(filterSettings).forEach((company) => {
      if( filterSettings[company].selected ) {
        temp.companies[company].visible = true
        let countTrue = 0
        temp.companies[company].tier = []
        Object.keys(filterSettings[company].tiers).forEach((tier) => {
          filterSettings[company].tiers[tier].forEach((subObject) => {
            if (subObject.selected) {
              countTrue += 1
              temp.companies[company].tier.push(tier + "," + subObject.desc)
            }
          })
        })
        if( countTrue === this.state.totalCount ) {
          temp.companies[company].tier = []
        }
      } else {
        temp.companies[company].visible = false
      }
    })
    this.setState({ filterSettings: filterSettings, companyParams: temp, isLoading: true })
    this.updateCompanies()
  }

  _renderTooltip() {
      let {hoveredObject, x, y} = this.state || {};
      return hoveredObject && (
        <div style={{
            margin: "5px",
            padding: "5px",
            position: 'absolute', 
            zIndex: 1, 
            backgroundColor: "#343a40",
            color: "white", 
            pointerEvents: 'none',
            left: x,
            top: y
          }}>
          {!this.state.connection && hoveredObject.properties.ADMIN }
          {this.state.tooltipData && this.state.connection && !this.state.tooltipLoading && 
              <ConnectionTooltip data={this.state.tooltipData} />
          }
          {this.state.hoveredObject.company && this.state.connection && this.state.tooltipLoading &&
              <Spinner animation="border" />
          }
        </div>
      );
  }

  _renderLayers(props) {
    return [
      new GeoJsonLayer({
        id: "base-map",
        data: props.geofeats,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 1,
        getLineColor: [200, 200, 200],
        pickable: true,
        getFillColor: f => {
          if((this.state.hoveredObject == null && !this.state.connection) || f.properties.GEOUNIT !== this.state.hoveredObject.properties.GEOUNIT)
          {
            return [239, 239, 239];
          } else return  [255, 255, 255];
        },
        updateTriggers: {
          getFillColor: [
            (this.state.hoveredObject && !this.state.connection) ? this.state.hoveredObject.properties.GEOUNIT : null
          ]
        },
        onHover: this._onHover,
        onClick: this._onCountryClick
      }),
      new ArcLayer({
        id: "aapl",
        data: props.arcs,
        pickable: true,
        getSourcePosition: f => f.srcGeo,
        getTargetPosition: f => f.destGeo,
        getSourceColor: f => {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getTargetColor: f=> {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getWidth: f => Math.max(100 * (f.netShare), 0.6),
        getTilt: 5,
	onClick: this._onConnectionClick,
        onHover: this._onConnectionHover
      }),
      new ArcLayer({
        id: "de",
        data: props.arcs2,
        pickable: true,
        getSourcePosition: f => f.srcGeo,
        getTargetPosition: f => f.destGeo,
        getSourceColor: f => {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getTargetColor: f=> {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getWidth: f => Math.max(100 * (f.netShare), 0.6),
        getTilt: 5,
        onHover: this._onConnectionHover,
        onClick: this._onConnectionClick
      }),
      new ArcLayer({
        id: "dell",
        data: props.arcs3,
        pickable: true,
        getSourcePosition: f => f.srcGeo,
        getTargetPosition: f => f.destGeo,
        getSourceColor: f => {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
	    return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
	    return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getTargetColor: f=> {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
	    return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getWidth: f => Math.max(100 * (f.netShare), 0.6),
        getTilt: 5,
        onHover: this._onConnectionHover,
        onClick: this._onConnectionClick
      }),
      new ArcLayer({
        id: "nke",
        data: props.arcs4,
        pickable: true,
        getSourcePosition: f => f.srcGeo,
        getTargetPosition: f => f.destGeo,
        getSourceColor: f => {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getTargetColor: f=> {
          if (f.tradeScore <= 50.0) {
            // return [(f.tradeScore/50.0 * 225), 225, 0]
            return Color(givenColor).rotate((50.0-f.tradeScore) / 50.0 * +51).saturate((50.0-f.tradeScore) / 50.0 * (0.14)).mix(Color('black'), (0.5) * (50.0-f.tradeScore) / 50.0).rgb().array();
          } else {
            // return [225, (1.0 - (f.tradeScore - 50.0)/50.0 * 225), 0]
            return Color(givenColor).rotate((f.tradeScore-50.0) / 50.0 * -67).saturate((f.tradeScore-50.0) / 50.0 * (0.2)).mix(Color('white'), (0.12) * (f.tradeScore-50.0) / 50.0).rgb().array();
          }
        },
        getWidth: f => Math.max(100 * (f.netShare), 0.6),
        getTilt: 5,
        onHover: this._onConnectionHover,
        onClick: this._onConnectionClick
      })
    ]
  }

  async closeMenu() {
    let temp = this.state.companyParams
    temp.country = ""
    this.setState({ showInfoBox: false, companyParams: temp, clickedObject: {}, passedData: {}, clickType: "" })
  }

  render() {
    return (
      <div>
        <DeckGL style={{backgroundColor: "rgb(208, 207, 212, 1)"}}
          controller={true}
          initialViewState={INITIAL_VIEW_STATE}
          layers={this._renderLayers({
            geofeats: this.state.map,
            centroids: this.state.centroids,
            arcs: this.state.arcData,
            arcs2: this.state.arcData2,
            arcs3: this.state.arcData3,
            arcs4: this.state.arcData4
          })}
        >
          {this.state.isLoading && this.state.startingUp &&
            <Loading style={{zIndex: "10"}} statusMessage={this.state.loadStatus} />
          }
          { this._renderTooltip() }
        </DeckGL>
        <div style={{position: "absolute"}}>
          {!this.state.isLoading && !this.state.showInfoBox &&
              <SideBar data={this.state.filterSettings} getFilterData={(data) => {this.setCompanyParams(data)}}/>
          }
          {!this.state.isLoading && this.state.showInfoBox &&
            <SlideInfo
              type={this.state.clickType}
              onClose={this.closeMenu}
              data={this.state.passedData}
            />
          }
        </div>
      </div>
    );
  }
}

export default InteractiveMap;

import Centroids from './centroid_points.json';
import WombatMap from './wombat_mercator_map.json';

import AAPL from './aapl.json';
import DELL from './dell.json';
import DE from './de.json';
import NKE from './nke.json';

const getCentroids = function() {
  return Centroids.features;
}

const getMap = function() {
  return WombatMap.features;
}

const getAapl = function(visible, atier, country) {
  let aaplTiers = {
      "1": "1,Raw Materials to Manufacturers",
      "2": "2,Manufacturers to Distributors",
      "3": "3,Distributors to Retailers"
  }
  try {
      if (visible == true) {
          let rawdata_fs = AAPL;
          let rawdata = null
          let returnAapl = null
          if (!country) {
              rawdata = rawdata_fs.connections
              returnAapl = { info: {}, connections: {} }
              Object.keys(rawdata).forEach((tier) => {
                  if (atier.length == 0 || (atier.length > 1 && atier.indexOf(aaplTiers[tier]) >= 0) || (aaplTiers[tier] === atier)) {
                      rawdata[tier].forEach((subTier) => {
                          returnAapl.info[tier] = [{}, {}]
                          returnAapl.connections[tier] = [[]]
                          let counter = 0
                          subTier.forEach((connection) => {
                              returnAapl.connections[tier][0].push(connection)
                              if (!returnAapl.info[tier][0][connection[0]]) {
                                  rawdata_fs.tier_data[parseInt(tier)-1]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[0]) {
                                          returnAapl.info[tier][0][connection[0]] = cntry[3]
                                      }
                                  })
                              }
                              if (!returnAapl.info[tier][1][connection[1]]) {
                                  rawdata_fs.tier_data[parseInt(tier)]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[1]) {
                                          returnAapl.info[tier][1][connection[1]] = cntry[3]
                                      }
                                  })
                              }
                              counter += 1
                          })
                      })
                  }
              })
          } else {
              Object.keys(rawdata_fs.countries).forEach((cntry) => {
                  if (cntry.toUpperCase() == country.toUpperCase()) {
                      rawdata = rawdata_fs.countries[cntry]
                  }
              })
              if (rawdata) {
                  returnAapl = {}
                  console.log(rawdata)
                  Object.keys(rawdata).forEach((tier) => {
                      returnAapl[tier] = [rawdata[tier]]
                  })
              }
          }
          if (!returnAapl) return { connections: [] };
          return returnAapl;
      } else {
          return { connections: [] };
      }
  } catch (err) {
      console.error(err)
      return false;
  }
}

const getDell = function(visible, atier, country) {
  let dellTiers = {
      "1": [
          "1,Tantalum to Manufacturers",
          "1,Tin to Manufacturers",
          "1,Gold to Manufacturers",
          "1,Tungsten to Manufacturers"
      ],
      "2": ["2,Manufacturers to Distributors"],
      "3": ["3,Distributors to Retailers"]
  }
  try {
      if (visible == true) {
          let rawdata_fs = DELL
          let rawdata = null
          let returnDell = null
          if (!country) {
              rawdata = rawdata_fs.connections
              returnDell = { info: {}, connections: {} }
              Object.keys(rawdata).forEach((tier) => {
                  returnDell.info[tier] = []
                  returnDell.connections[tier] = []
                  dellTiers[tier].forEach((label, index) => {
                      returnDell.info[tier].push([{}, {}])
                      returnDell.connections[tier].push([])
                      if (atier.length == 0 || (atier.length > 1 && atier.indexOf(dellTiers[tier][index]) >= 0) || (dellTiers[tier][index] === atier)) {
                          let counter = 0
                          rawdata[tier][index].forEach((connection) => {
                              returnDell.connections[tier][index].push(connection)
                              if (!returnDell.info[tier][index][0][connection[0]]) {
                                  rawdata_fs.tier_data[parseInt(tier)-1]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[0]) {
                                          returnDell.info[tier][index][0][connection[0]] = cntry[3]
                                      }
                                  })
                              }
                              if (!returnDell.info[tier][index][1][connection[1]]) {
                                  rawdata_fs.tier_data[parseInt(tier)]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[1]) {
                                          returnDell.info[tier][index][1][connection[1]] = cntry[3]
                                      }
                                  })
                              }
                              counter += 1
                          })
                      }
                  })
              })
          } else {
              Object.keys(rawdata_fs.countries).forEach((cntry) => {
                  if (cntry.toUpperCase() == country.toUpperCase()) {
                      rawdata = rawdata_fs.countries[cntry]
                  }
              })
              if (rawdata) {
                  returnDell = {}
                  Object.keys(rawdata).forEach((tier) => {
                      returnDell[tier] = []
                      rawdata[tier].forEach((label, labelIndex) => {
                          returnDell[tier].push({})
                          returnDell[tier][labelIndex] = rawdata[tier][labelIndex]
                      })
                  })
              }
          }
          if (!returnDell) return { connections: [] };
          return returnDell;
      } else {
          return { connections: [] };
      }
  } catch (err) {
      console.error(err)
      return false;
  }
}

const getDe = function(visible, atier, country) {
  let deTiers = {
      "1": "1,Raw Materials to Manufacturers",
      "2": "2,Manufacturers to Distributors",
      "3": "3,Distributors to Retailers"
  }
  try {
      if (visible == true) {
          let rawdata_fs = DE
          let rawdata = null
          let returnDe = null
          if (!country) {
              rawdata = rawdata_fs.connections
              returnDe = { info: {}, connections: {} }
              Object.keys(rawdata).forEach((tier) => {
                  if (atier.length == 0 || (atier.length > 1 && atier.indexOf(deTiers[tier]) >= 0) || (deTiers[tier] === atier)) {
                      rawdata[tier].forEach((subTier) => {
                          returnDe.info[tier] = [{}, {}]
                          returnDe.connections[tier] = [[]]
                          let counter = 0
                          subTier.forEach((connection) => {
                              returnDe.connections[tier][0].push(connection)
                              if (!returnDe.info[tier][0][connection[0]]) {
                                  rawdata_fs.tier_data[parseInt(tier)-1]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[0]) {
                                          returnDe.info[tier][0][connection[0]] = cntry[3]
                                      }
                                  })
                              }
                              if (!returnDe.info[tier][1][connection[1]]) {
                                  rawdata_fs.tier_data[parseInt(tier)]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[1]) {
                                          returnDe.info[tier][1][connection[1]] = cntry[3]
                                      }
                                  })
                              }
                              counter += 1
                          })
                      })
                  }
              })
          } else {
              Object.keys(rawdata_fs.countries).forEach((cntry) => {
                  if (cntry.toUpperCase() == country.toUpperCase()) {
                      rawdata = rawdata_fs.countries[cntry]
                  }
              })
              if (rawdata) {
                  returnDe = {}
                  Object.keys(rawdata).forEach((tier) => {
                      returnDe[tier] = [rawdata[tier]]
                  })
              }
          }
          if (!returnDe) return { connections: [] };
          return returnDe;
      } else {
          return { connections: [] };
      }
  } catch (err) {
      console.error(err)
      return false;
  }
}

const getNke = function(visible, atier, country) {
  let nkeTiers = {
      "1": ["1,Raw Materials to Manufacturers"],
      "2": ["2,Manufacturers to Distributors"],
      "3": ["3,Distributors to Retailers"]
  }
  try {
      if (visible == true) {
          let rawdata_fs = NKE
          let rawdata = null
          let returnNke = null
          if (!country) {
              rawdata = rawdata_fs.connections
              returnNke = { info: {}, connections: {} }
              Object.keys(rawdata).forEach((tier) => {
                  returnNke.info[tier] = []
                  returnNke.connections[tier] = []
                  nkeTiers[tier].forEach((label, index) => {
                      returnNke.info[tier].push([{}, {}])
                      returnNke.connections[tier].push([])
                      if (atier.length == 0 || (atier.length > 1 && atier.indexOf(nkeTiers[tier][index]) >= 0) || (nkeTiers[tier][index] === atier)) {
                          let counter = 0
                          rawdata[tier][index].forEach((connection) => {
                              returnNke.connections[tier][index].push(connection)
                              if (!returnNke.info[tier][index][0][connection[0]]) {
                                  rawdata_fs.tier_data[parseInt(tier)-1]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[0]) {
                                          returnNke.info[tier][index][0][connection[0]] = cntry[3]
                                      }
                                  })
                              }
                              if (!returnNke.info[tier][index][1][connection[1]]) {
                                  rawdata_fs.tier_data[parseInt(tier)]["1"].forEach((cntry) => {
                                      if (cntry[0] == connection[1]) {
                                          returnNke.info[tier][index][1][connection[1]] = cntry[3]
                                      }
                                  })
                              }
                              counter += 1
                          })
                      }
                  })
              })
          } else {
              Object.keys(rawdata_fs.countries).forEach((cntry) => {
                  if (cntry.toUpperCase() == country.toUpperCase()) {
                      rawdata = rawdata_fs.countries[cntry]
                  }
              })
              if (rawdata) {
                  returnNke = {}
                  Object.keys(rawdata).forEach((tier) => {
                      returnNke[tier] = []
                      rawdata[tier].forEach((label, labelIndex) => {
                          returnNke[tier].push({})
                          returnNke[tier][labelIndex] = rawdata[tier][labelIndex]
                      })
                  })
              }
          }
          if (!returnNke) return { connections: [] };
          return returnNke;
      } else {
          return { connections: [] };
      }
  } catch (err) {
      console.error(err)
      return false;
  }
}


export default { getCentroids, getMap, getAapl, getDell, getDe, getNke };

/*
toUrlString( objectVar, country ) {
    let returnStr = ""
    if (objectVar.visible) {
      returnStr += ("visible=" + objectVar.visible)
    }
    if (objectVar.tier) {
      objectVar.tier.forEach((str) => {
        returnStr += ("&tier=" + str)
      })
    }
    if (country !== "") {
      returnStr += ("&country=" + country)
    }
    return returnStr
  }
  */